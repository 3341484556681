/* .app-modal-main {
  width: 30vw;
  background-color: #fff;
  border-radius: 30px;
  padding: 20px;
  outline: none;
} */

.submit-btn {
  height: 40px;
  width: 150px;
  border-radius: 30px;
  border: 0;
  background-color: var(--cl_violet);
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: rgb(69, 15, 168);
}


.suggestion-main-layout {
  position: absolute;
  width: 100%;
  background-color: rgb(230, 230, 230);
  z-index: 999;
  max-height: 320px;
  overflow: scroll;
  transition: height 700ms linear;
  
}

.suggestion-list-container {
  padding: 0 20px;
}

.student-suggestion-list {
  font-size: 16px;
  list-style: none;
  padding: 10px 10px;
  margin: 0;
  cursor: pointer;
  margin-top: 10px;
}

.student-suggestion-list:hover {
  background-color: rgb(212, 212, 212);
  border-radius: 5px;
}
