.sidebar-layout {
  min-width: 80px;
  height: 100vh;
  width: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 20px; */
}

.sidebar-menu-layout {
  min-width: 60px;
  min-height: 60px;
  height: 6vw;
  width: 6vw;
  border-radius: 1rem;
  background-color: rgb(214, 214, 214);
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: gray;
  text-decoration: none;
}

.sidebar-menu-layout-active {
  background-color: #fff;
  color: #000;
}

.sidebar-menu-layout:hover {
  background-color: #fff;
  color: #000;
}

.sidebar-menu-layout p {
  font-size: 12px;
  font-weight: 500;
  padding-top: 10px;
}

.hamburger {
  display: none;
  z-index: 1;
  pointer-events: none;
}

#demo-simple-select > span[name='acdYrEditOpts']{
  display: none;
}
/* ####################################################### PROFILE SECTION ############################################# */
.profile-menu-layout {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--cl_white);
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

/* ############################################################################################################################################################## */

/*  ####################################### LARGE TABLET STYLING ####################################### */

/* 
@media screen and (max-width :1000px) {

  .sidebar-layout{
    display: flex;
    justify-content: left;
  }

  .sidebar-menu-layout{
  
  display: none;

  
  }

  .hamburger{
    position: fixed;
    display: block;
    z-index: 2;
    pointer-events: all;
    cursor: pointer;
    top : 10%;
    left: 3%;
  }

  .profile-menu-layout {
    display: none;
    
  }
    
  } */
